import http from "../http/http";

//密码登陆
export function LoginByPass(params) {
  return http.post("/api/User/LoginByPass", params);
}

//验证码登陆
export function LoginByVCode(params) {
  return http.post("/api/User/LoginByVCode", params);
}

//验证码
// params：phone
export function SendVCode(params) {
  return http.get("/api/User/SendVCode", params);
}
