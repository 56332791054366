import http from "../http/http";
//首页数据
export function IndexData(params) {
  return http.post("/api/Training/IndexData", params);
}

//课程详情及课时列表
// params：courseId
export function GetCourse(params) {
  return http.get("/api/Training/GetCourse", params);
}

//下载课件
// params：cwId
export function GetCourseware(params) {
  return http.get("/api/Training/GetCourseware", params);
}

//课时资源列表
// params：ID
export function GetCourseResources(params) {
  return http.get("/api/Training/GetCourseResources", params);
}

//学习课程调用
// params：课时资源列表的ID
export function StudyResource(params) {
  return http.get("/api/Training/StudyResource", params);
}

//更新学习时长
// params：     type
// courseId		  int
// resourceId	  int
// time         int
export function UpdateStudyTime(params) {
  return http.get("/api/Training/UpdateStudyTime", params);
}

//学习完成
// params：     type
// courseId		  int	  课程ID
// resourceId		int	  资源ID
export function StudyFinish(params) {
  return http.get("/api/Training/StudyFinish", params);
}

//最近学习记录
//  params：null
export function HistoryRecord(params) {
  return http.post("/api/Training/HistoryRecord", params);
}

//已购模块课程
//  params：null
export function ModuleCourses(params) {
  return http.post("/api/Training/ModuleCourses", params);
}
