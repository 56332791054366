export const validateAccount = (rule, value, callback) => {
  if (value === "") {
    return callback(new Error("用户名不能为空"));
  } else {
    callback();
  }
};
export const validatePass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("密码不能为空"));
  } else {
    callback();
  }
};

export const validatePhone = (rule, value, callback) => {
  if (value === "") {
    return callback(new Error("手机号不能为空"));
  } else {
    callback();
  }
};
export const validateCaptcha = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("验证码不能为空"));
  } else {
    callback();
  }
};
