import { createStore } from "vuex";

export default createStore({
  state: {
    //课程列表
    courses: [],
    types: [],
    //课程id
    CourseID: "",
    //课程是否学完
    IsFinish: "",
    //学习章节
    chapterList: [],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
