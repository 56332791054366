import JSEncrypt from "jsencrypt";

const encryptor = new JSEncrypt();

const publicKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCLw4lOHVgs/SkywaCejOZLiA6PTDoKIFUZFcErsdD5Zsvoc1LBnqqx5kldr6nAHLx6zc/LcnDhOy1mdg909KQSLS+FmKJXUYpgXxPwFItYxV/GWpwp/EP8H6yLo4+2+wIbuArhsgaDLoAEt4a81mglmd5JQICvyZmKo6668/0xJQIDAQAB";
// 私钥key
const privateKey =
  "MIICXAIBAAKBgQCLw4lOHVgs/SkywaCejOZLiA6PTDoKIFUZFcErsdD5Zsvoc1LB" +
  "nqqx5kldr6nAHLx6zc/LcnDhOy1mdg909KQSLS+FmKJXUYpgXxPwFItYxV/GWpwp" +
  "/EP8H6yLo4+2+wIbuArhsgaDLoAEt4a81mglmd5JQICvyZmKo6668/0xJQIDAQAB" +
  "AoGAAisgrCKz61lYn9PMnCNucAFbKDBfeShkDQsfCxvnEBlDmQHdVnJ/cv7NqGOR" +
  "KLXeAA1X7GbEVPG0pDq5Dwc79f9TSGwyQXTjefgNpjvsUX+n82J8AnLsrre8Wn0k" +
  "VozZXFKINfMQlXH5aE6URzKH5HgqQCwwOo0MLRPNmytCmtECQQCoN6xAjXu6Ytk2" +
  "ozkTewfWEdo+KjqjzWXLsbchysKjdhLKl97HH+k2dQEIPPukCbIigGevhtKw8KrA" +
  "TM08XmqRAkEA1LK2ivS/CGJzKYHqVkOUObRl+0+F2spaaRwyAwNoH+3to1dSZ+uy" +
  "eXOK/mbc4EVdSZkWNaMdhgfEemCYiZhfVQJAPqmGfAF4TqUV3xmMYGpbL+40iyW1" +
  "9GDGA8vuuSLLwJJyS4+pSCnl+Qg62zPEVYXbGqkUdrlVcN4l7T5u0YcmUQJBAIk7" +
  "NSO6fXa/bmYdeEBoIb6JcjMPP+HGJUcj9fCTxh6zuFtm/7glDmXl8ovpeiEJIANY" +
  "TkgVEftkD5SVtlUwhnECQDUlsUsFjsZGCWgo0epwmgubcJIq8IgzVCrOQBgxHAUJ" +
  "oOZQchrNqZd5SsNvEhtvCPW8wAyQlPS99+KZ+7X92Fg=";
// 加密
export function encrypt(txt) {
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  encryptor.setPrivateKey(privateKey); // 设置私钥
  return encryptor.decrypt(txt); // 对数据进行解密
}
