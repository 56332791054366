import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import indexView from "@/views/components/indexView.vue";
import login from "@/views/loginView.vue";
import { ElMessage } from "element-plus";
// import { jwtDecode } from "jwt-decode";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: { requiresAuth: true, redirectTo: "/home" },
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "",
        name: "index",
        component: indexView,
        meta: { requiresAuth: false },
      },
      {
        path: "/course",
        name: "course",
        component: () => import("@/views/components/courseView.vue"),
      },
      {
        path: "/history",
        name: "/history",
        component: () => import("@/views/components/historyView.vue"),
      },
      {
        path: "/examHistory",
        name: "examHistory",
        component: () => import("@/views/exam/examHistoryView.vue"),
      },
    ],
  },
  {
    path: "/video",
    name: "video",
    component: () => import("@/views/videoView.vue"),
  },
  {
    path: "/exam",
    name: "exam",
    component: () => import("@/views/exam/examView.vue"),
    meta: {
      target: "_blank", // 添加该属性
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  next();
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token"); // 从localStorage或其他地方获取令牌
  // 检查令牌是否存在或有效，根据实际需求进行逻辑判断
  const expiresIn = Number(localStorage.getItem("expiresIn"));
  // console.log(typeof new Date().getTime(), typeof expiresIn);
  if (
    (to.name !== "login" && !token) ||
    (to.name !== "login" && new Date().getTime() > expiresIn)
  ) {
    localStorage.clear();
    next({
      name: "login",
    }); // 重定向到登录页面
    ElMessage.error("登陆信息已失效，请重新登录");
  } else if (
    (to.name == "login" && token) ||
    (to.name == "login" && new Date().getTime() < expiresIn)
  ) {
    next({
      path: to.meta.redirectTo,
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    window.location.href = "http://m.train.f3dexpress.com/";
    return;
  }
  next();
});

export default router;
