import axios from "axios";
import { ElMessage } from "element-plus";
import router from "@/router";
axios.defaults.baseURL = "http://trainapi.f3dexpress.com";
// axios.defaults.baseURL = "http://192.168.1.168:568";
// axios.defaults.baseURL = process.env.VUE_APP_URL;

const instance = axios.create({
  timeout: 6000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});
instance.interceptors.request.use(
  (config) => {
    // console.log(config);
    const token = localStorage.getItem("token");
    config.headers["f3dtoken"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.IsError) {
      if (res.Message == "身份凭证异常") {
        router.push("/login");
        localStorage.clear();
        // ElMessage.error("身份凭证异常");
      } else {
        ElMessage.error(res.Message);
      }
    } else {
      return res;
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);
export default instance;
